<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :list-item-lines="3"
        :show-alerts="!routeFromBrowserHistory"
        @click-row="clickSummaryRow"
      >
        <template slot="extraSubtitle">
          <extra-subtitle
            v-if="formattedItem.parte_trabajo"
            :ffijada="formattedItem.parte_trabajo.ffijada"
            :avisar-antes-ir="formattedItem.parte_trabajo.avisar_antes_ir"
            :mano-de-obra-facturable="formattedItem.parte_trabajo.manodeobrafacturable"
            :idtfacturacion-kilometros="formattedItem.parte_trabajo.idtfacturacion_kilometros"
            :cant-acciones-realizadas="formattedItem.cantAccionesRealizadas"
            :cant-acciones-total="formattedItem.cantAccionesTotal"
            :tmotivo-bloqueo="formattedItem.tmotivo_bloqueo_ot"
            :proximas-revisiones="formattedItem.proximasRevisiones"
          />
        </template>
        <template
          slot="cardActionsLeft"
        >
          <v-btn
            v-if="formattedItem.checklist_ot && formattedItem.checklist_ot.idchecklist_ot"
            icon
            color="primary"
            @click.stop="clickIrAChecklist"
          >
            <v-icon>{{ $vuetify.icons.values.checklist }}</v-icon>
          </v-btn>
        </template>
      </b10-view-summary>
      <b10-view-details
        :details="details"
        @click-detail="clickDetail"
      />
      <b10-fab-button
        v-if="estadoPartePendiente"
        :icon="$vuetify.icons.values.search"
        @click="clickBuscarMaterial"
      />
      <b10-fab-button
        v-else-if="networkOnline"
        color="red"
        :icon="$vuetify.icons.values.upload"
        x-large
        :hide-on-scroll="false"
        @click="clickSubirParte"
      />
      <buscar-material-afectado
        :showing.sync="showingDialogs.buscarMaterialAfectado"
        :idparte-trabajo="routeParams.idparte_trabajo"
        @click-buscar-avanzado="clickBuscarMaterialAvanzado"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import { viewPageMixin } from '@/mixins/viewPageMixin'
import toolbarOptions from './toolbarOptions'
import _ from '@/utils/lodash'
import { get } from 'vuex-pathify'
import Data from './ParteTrabajoViewData'
import ExtraSubtitle from './components/ExtraSubtitle'
import { TABLA, PARTE_TRABAJO, CHECKLIST, SyncError } from '@/utils/consts'
import BuscarMaterialAfectado from '../components/BuscarMaterialAfectado'
import { uploadParteTrabajo, doSync } from '@/sync/upload'
import { captureGeolocation, getLatLongURL } from '@/utils/maps'
import { encodeExtraQuery } from '@/utils/router'
import  filters from '@/utils/filters'

export default {
  components: {
    ExtraSubtitle, BuscarMaterialAfectado,
  },
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      toolbarOptions: toolbarOptions(),
      moreInfoRows: {
        parte: {
          title: 'Parte',
          rows: [
            { name: 'parte_trabajo.idparte_trabajo', label: 'Nº parte' },
            { name: 'parte_trabajo.fparte', label: 'Fecha de parte', filter: this.$options.filters.shortDate },
            { name: 'orden_trabajo.orden_trabajo_serie_numero', label: 'Nº orden de trabajo' },
            { name: 'tactuacion.descripcion', label: 'Tipo de actuación' },
            { name: 'orden_trabajo_prioridad.descripcion', label: 'Prioridad' },
            { name: 'almacen.descripcion', label: 'Almacén' },
            { name: 'parte_trabajo.ffijada', label: 'Fecha fijada', filter: this.$options.filters.shortDateTime },
            { name: 'parte_trabajo.fprevista_fin', label: 'Fecha prevista de fin', filter: this.$options.filters.shortDateTime },
            { name: 'orden_trabajo.fprevista', label: 'Fecha prevista', filter: this.$options.filters.shortDate },
            { name: 'parte_trabajo.manodeobrafacturable', label: 'Mano de obra facturable', filter: this.$options.filters.humanizeBoolean },
            { name: 'parte_trabajo.tfacturacion_kilometros_desc', label: 'Desplazamiento' },
            { name: 'parte_trabajo.avisar_antes_ir', label: 'Avisar antes de ir', filter: this.$options.filters.humanizeBoolean },
            { name: 'orden_trabajo.solicitante', label: 'Solicitante' },
            { name: 'tincidencia_ot.descripcion', label: 'Tipo de incidencia' },
            { name: 'tcierre_parte.descripcion', label: 'Tipo de cierre' },
            { name: 'orden_trabajo.notas_internas', label: 'Notas internas' },
            { name: 'parte_trabajo.observacion', label: 'Observaciones' },
            { name: 'parte_trabajo.firmante', label: 'Firmante' },
            { name: 'parte_trabajo.dni_firmante', label: 'DNI firmante' },
          ],
        },
        sistema: {
          title: 'Sistema',
          rows: [
            { name: 'sistema.codigo', label: 'Código' },
            { name: 'sistema.nabonado1', label: 'Nº abonado' },
            { name: 'sistema.descripcion', label: 'Sistema', clickable: true },
            { name: 'tsistema.descripcion', label: 'Tipo de sistema' },
            { name: 'direccion', label: 'Dirección' },
            { name: 'cliente_direccion.observacion', label: 'Observaciones' },
            { name: 'zona.descripcion', label: 'Zona' },
            { name: 'sistema.tactividad_descripcion', label: 'Actividad' },
            { name: 'sistema.central_alarma_nombre', label: 'Central de alarma' },
            { name: 'sistema.comercial_nombre', label: 'Comercial' },
            { name: 'sistema.agente_nombre', label: 'Agente' },
          ],
        },
        cliente: {
          title: 'Cliente',
          rows: [
            { name: 'cliente.idcliente', label: 'Nº cliente' },
            { name: 'cliente.nombre', label: 'Cliente' },
          ],
        },
      },
      showingDialogs: {
        moreInfo: false,
        buscarMaterialAfectado: false,
      },
      PARTE_TRABAJO,
      avisos: '',
      nserieFaltantes: [],
      datasetTecnicosAsignados: {},
    }
  },
  computed: {
    usuarioIdtecnico: get('usuario/idtecnico'),
    usuarioIdcomercial: get('usuario/idcomercial'),
    cronometroIdparte: get('cronometro/idparte'),
    networkOnline: get('network/online'),
    estadoPartePendiente () {
      return this.item?.dataset?.parte_trabajo?.estado === PARTE_TRABAJO.estados.pendiente
    },
    estadoParteFinalizado () {
      return this.item?.dataset?.parte_trabajo?.estado === PARTE_TRABAJO.estados.finalizado
    },
    estadoChecklistPendiente () {
      return this.item?.dataset?.checklist_ot?.estado === CHECKLIST.estados.pendiente
    },
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.parte_trabajo.idparte_trabajo
        item.title = this.$offline.parteTrabajo.title(item)
        item.subtitle = this.$offline.parteTrabajo.subtitle(item)
        item.alerts = []
        if (this.avisos) {
          item.alerts.push({
            value: this.avisos,
            type: 'warning',
            options: {
              persistent: true,
            },
          })
        }
        if (item.orden_trabajo?.cant_partes_pendientes > 1) {
          item.alerts.push({ value: 'OT con más de un parte de trabajo pendiente', type: 'warning' })
        }
        if (item.emails.length === 0) {
          item.alerts.push({
            value: 'Cliente sin dirección de correo electrónico de servicio técnico',
            type: 'warning',
            options: { persistent: true, actions: [{ text: 'Añadir', flat: true, handler: this.clickIrContactos }], },
          })
        }
        if (this.estadoPartePendiente && this.cronometroIdparte === parseInt(this.routeParams.idparte_trabajo)) {
          item.badge = 'Cronómetro iniciado'
          item.badgeColor = PARTE_TRABAJO.colores.enCurso
        } else if (this.estadoPartePendiente) {
          item.badge = 'Pendiente'
          item.badgeColor = PARTE_TRABAJO.colores.pendiente
        } else if (this.estadoParteFinalizado) {
          item.badge = 'Finalizado'
          item.badgeColor = PARTE_TRABAJO.colores.finalizado
        }
        item.moreInfo = this.buildMoreInfo()
        return item
      } else {
        return {}
      }
    },
  },
  watch: {
    networkOnline (newValue) {
      this.toolbarOptions.irOrden.visible = !!newValue
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = `${this.item.dataset.orden_trabajo.orden_trabajo_serie_numero} > ${this.routeParams.idparte_trabajo}`
      this.toolbarOptions.resumenYFirma.visible = this.estadoParteFinalizado
      this.toolbarOptions.subirParte.visible = this.estadoParteFinalizado
      this.toolbarOptions.reabrirParte.visible = (
        this.estadoParteFinalizado && this.hasViewPerm(this.permissions.partes.reabrir)
      )
      this.toolbarOptions.modificar.visible = this.estadoPartePendiente
      this.toolbarOptions.irOrden.visible = !!this.networkOnline
      if (this.estadoPartePendiente) {
        if (this.cronometroIdparte === parseInt(this.routeParams.idparte_trabajo)) {
          // cronómetro iniciado en este parte
          this.toolbarOptions.iniciarCrono.visible = false
          this.toolbarOptions.detenerCrono.visible = true
          this.toolbarOptions.finalizar.visible = false
          this.toolbarOptions.subirParteSinFinalizar.visible = false
        } else if (!this.cronometroIdparte) {
          // cronometro no iniciado
          this.toolbarOptions.iniciarCrono.visible = true
          this.toolbarOptions.detenerCrono.visible = false
          this.toolbarOptions.finalizar.visible = true
          this.toolbarOptions.subirParteSinFinalizar.visible = true
        } else {
          // cronometro iniciado en otro parte
          this.toolbarOptions.iniciarCrono.visible = false
          this.toolbarOptions.detenerCrono.visible = false
          this.toolbarOptions.finalizar.visible = true
          this.toolbarOptions.subirParteSinFinalizar.visible = true
        }
      } else {
        this.toolbarOptions.iniciarCrono.visible = false
        this.toolbarOptions.detenerCrono.visible = false
        this.toolbarOptions.finalizar.visible = false
        this.toolbarOptions.subirParteSinFinalizar.visible = false
      }
      this.toolbarOptions.checklist.visible = !!this.item.dataset.checklist_ot.idchecklist_ot
      this.toolbarOptions.marcarTodoCorrecto.visible = (
        !!this.item.dataset.checklist_ot.idchecklist_ot &&
        this.estadoChecklistPendiente &&
        this.hasViewPerm(this.permissions.partes.marcarPreguntasTodoCorrecto)
      )
      this.avisos = await this.$offline.clienteAviso.avisos(
        this.item.dataset.cliente.idcliente,
        this.item.dataset.sistema.idsistema,
        !!this.usuarioIdtecnico,
        !!this.usuarioIdcomercial
      )
      await this.loadDetailTotals()
    },
    async loadItem () {
      this.loadingData = true
      try {
        const dataset = await Data.selectParteTrabajo(this, this.routeParams.idparte_trabajo)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.toolbarOptions.map.visible = !!this.item.dataset.cliente_direccion.latitud && !!this.item.dataset.cliente_direccion.longitud
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async loadDetailTotals () {
      this.initDetails()
      // material a instalar
      const detailMaterialInstalar = this.addDetail(
        'materialInstalar', 'Material a instalar/facturar', 'Material a instalar y/o servicios a facturar', 'materialInstalar'
      )
      const datasetMaterialInstalar = await Data.selectMaterialInstalarCount(this, this.routeParams.idparte_trabajo)
      let countMaterialInstalar = 0
      let totalsMaterialInstalar = []
      for (const rowMaterialInstalar of datasetMaterialInstalar) {
        countMaterialInstalar += rowMaterialInstalar.count
        totalsMaterialInstalar.push(`${rowMaterialInstalar.subsis.descripcion} (${filters.decimal(rowMaterialInstalar.count)})`)
      }
      detailMaterialInstalar.badge = filters.decimal(countMaterialInstalar) || 0
      detailMaterialInstalar.totals = totalsMaterialInstalar.join(', ')
      // material afectado
      const detailMaterialAfectado = this.addDetail(
        'materialAfectado', 'Material afectado', 'Afectado a revisiones o averías', 'materialAfectado'
      )
      const datasetMaterialAfectado = await Data.selectMaterialAfectadoCount(this, this.routeParams.idparte_trabajo)
      let countMaterialAfectado = 0
      let totalsMaterialAfectado = []
      for (const rowMaterialAfectado of datasetMaterialAfectado) {
        countMaterialAfectado += rowMaterialAfectado.count
        totalsMaterialAfectado.push(`${rowMaterialAfectado.subsis.descripcion} (${rowMaterialAfectado.count.toFixed()})`)
      }
      detailMaterialAfectado.badge = countMaterialAfectado || 0
      detailMaterialAfectado.totals = totalsMaterialAfectado.join(', ')
      // material retirado
      const datasetMaterialRetirado = await Data.selectMaterialRetiradoCount(this, this.routeParams.idparte_trabajo)
      if (datasetMaterialRetirado.length > 0) {
        const detailMaterialRetirado = this.addDetail(
          'materialRetirado', 'Material retirado', 'Material retirado para acciones de taller', 'retiradaTemporal'
        )
        let countMaterialRetirado = 0
        let totalsMaterialRetirado = []
        for (const rowMaterialRetirado of datasetMaterialRetirado) {
          countMaterialRetirado += rowMaterialRetirado.count
          totalsMaterialRetirado.push(`${rowMaterialRetirado.subsis.descripcion} (${rowMaterialRetirado.count.toFixed()})`)
        }
        detailMaterialRetirado.badge = countMaterialRetirado || 0
        detailMaterialRetirado.totals = totalsMaterialRetirado.join(', ')
      }
      // subsis
      const detailSubsis = this.addDetail(
        'subsis', 'Subsistemas afectados', 'Afectado a instalaciones, revisiones o averías', 'subsistema'
      )
      const datasetSubsis = await Data.selectSubsisCount(this, this.routeParams.idparte_trabajo)
      let countSubsis = 0
      let totalsSubsis = []
      for (const rowSubsis of datasetSubsis) {
        countSubsis += 1
        totalsSubsis.push(rowSubsis.subsis.descripcion)
      }
      detailSubsis.badge = countSubsis || 0
      detailSubsis.totals = totalsSubsis.join(', ')
      // personas de contacto
      if (this.hasViewPerm(this.permissions.sistemaTelefono.id)) {
        const detailPersonasContacto = this.addDetail(
          'personasContacto', 'Personas de contacto', 'Personas de contacto del cliente y sistema', 'personaContacto'
        )
        const datasetPersonasContacto = await Data.selectPersonasContactoCount(this, this.item.dataset.sistema.idsistema)
        detailPersonasContacto.badge = datasetPersonasContacto.length || 0
      }
      // servicios contratados
      const detailServiciosContratados = this.addDetail(
        'serviciosContratados', 'Servicios contratados', 'Servicios contratados y cuotas de mantenimiento', 'servicioContratado'
      )
      const datasetServiciosContratados = await Data.selectSistemaCuotaCount(this, this.routeParams.idparte_trabajo)
      let countServiciosContratados = 0
      let totalsServiciosContratados = []
      for (const rowServiciosContratados of datasetServiciosContratados) {
        countServiciosContratados += 1
        totalsServiciosContratados.push(rowServiciosContratados.concepto_cuota.descripcion_cuota)
      }
      detailServiciosContratados.badge = countServiciosContratados || 0
      detailServiciosContratados.totals = totalsServiciosContratados.join(', ')
      // revisiones de sistema
      const detailRevisiones = this.addDetail(
        'revisiones', 'Revisiones', 'Revisiones de sistema programadas', 'revision'
      )
      const datasetRevisiones = await Data.selectRevisionesCount(this, this.routeParams.idparte_trabajo)
      let countRevisiones = 0
      let totalsRevisiones = []
      for (const rowRevision of datasetRevisiones) {
        countRevisiones += rowRevision.count
        totalsRevisiones.push(
          `${rowRevision.tactuacion.descripcion} ${rowRevision.periodo_revision.descripcion} (${rowRevision.count.toFixed()})`
        )
      }
      detailRevisiones.badge = countRevisiones || 0
      detailRevisiones.totals = totalsRevisiones.join(', ')
      // tiempo trabajado
      if (this.hasViewPerm(this.permissions.parteTrabajoTiempoTrabajado.id)) {
        const detailTiempoTrabajado = this.addDetail(
          'tiempoTrabajado', 'Tiempo trabajado', 'Registro de tiempo trabajado y desplazamientos', 'tiempoTrabajado'
        )
        const datasetTiempoTrabajado = await Data.selectTiempoTrabajadoCount(this, this.routeParams.idparte_trabajo)
        const tiempoManoObra = this.$options.filters.humanizeDuration(datasetTiempoTrabajado.tiempoManoObra)
        const tiempoDesplazamiento = this.$options.filters.humanizeDuration(datasetTiempoTrabajado.tiempoDesplazamiento)
        detailTiempoTrabajado.badge = datasetTiempoTrabajado.count || 0
        if ((datasetTiempoTrabajado.count || 0) === 0) {
          // para destacar que falta introducir tiempo
          detailTiempoTrabajado.badgeColor = 'error'
        }
        detailTiempoTrabajado.totals = `Trabajado: ${tiempoManoObra} / desplazamiento: ${tiempoDesplazamiento}`
      }
      // ficheros
      if (this.hasViewPerm(this.permissions.parteTrabajoAdjunto.id)) {
        const detailFicheros = this.addDetail(
          'ficheros', 'Adjuntos', 'Adjuntos del parte de trabajo, la orden y el sistema', 'attach'
        )
        const datasetFicheros = await Data.selectFicherosCount(
          this,
          this.routeParams.idparte_trabajo,
          this.item.dataset.orden_trabajo.idorden_trabajo,
          this.item.dataset.sistema.idsistema,
        )
        detailFicheros.badge = datasetFicheros.count || 0
      }
      // técnicos asignados
      const detailTecnicosAsignados = this.addDetail(
        'tecnicosAsignados', 'Técnicos asignados', '', 'asignado'
      )
      this.datasetTecnicosAsignados = await Data.selectTecnicosAsignadosCount(this, this.routeParams.idparte_trabajo)
      const tecnicosAsignados = _.map(this.datasetTecnicosAsignados, 'empleado.nombre_y_apellido')
      detailTecnicosAsignados.badge = this.datasetTecnicosAsignados.length
      detailTecnicosAsignados.totals = tecnicosAsignados.join(', ')
    },
    clickDetail (data) {
      if (data.detail.name === 'materialAfectado') {
        this.$appRouter.push({
          name: 'offline__lparte-trabajo-material-afectado-subsis-list',
          params: {
            idparte_trabajo: this.routeParams.idparte_trabajo,
          },
        })
      } else if (data.detail.name === 'materialRetirado') {
        this.$appRouter.push({
          name: 'offline__lparte-trabajo-material-afectado-subsis-list',
          params: {
            idparte_trabajo: this.routeParams.idparte_trabajo,
          },
          query: {
            retirado: true,
          },
        })
      } else if (data.detail.name === 'subsis') {
        this.$appRouter.push({
          name: 'offline__orden-trabajo-subsis-list',
          params: {
            idorden_trabajo: this.item.dataset.orden_trabajo.idorden_trabajo,
            idparte_trabajo: this.routeParams.idparte_trabajo,
          },
        })
      } else if (data.detail.name === 'materialInstalar') {
        this.$appRouter.push({
          name: 'offline__lparte-trabajo-subsis-list',
          params: {
            idparte_trabajo: this.routeParams.idparte_trabajo,
          },
        })
      } else if (data.detail.name === 'tiempoTrabajado') {
        this.$appRouter.push({
          name: 'offline__tiempo-trabajado-list',
          query: {
            idparte_trabajo: this.routeParams.idparte_trabajo,
          },
        })
      } else if (data.detail.name === 'tecnicosAsignados') {
        this.$appRouter.push({
          name: 'offline__parte-trabajo-tecnico-list',
          params: {
            idparte_trabajo: this.routeParams.idparte_trabajo,
          },
        })
      } else if (data.detail.name === 'personasContacto') {
        this.$appRouter.push({
          name: 'offline__cliente-telefono-list',
          params: {
            idcliente: this.item.dataset.cliente.idcliente,
          },
          query: {
            idsistema: this.item.dataset.sistema.idsistema,
          },
        })
      } else if (data.detail.name === 'serviciosContratados') {
        this.$appRouter.push({
          name: 'offline__sistema-cuota-list',
          params: {
            idsistema: this.item.dataset.sistema.idsistema,
          },
        })
      } else if (data.detail.name === 'revisiones') {
        this.$appRouter.push({
          name: 'offline__sistema-mant-list',
          params: {
            idsistema: this.item.dataset.sistema.idsistema,
          },
        })
      } else if (data.detail.name === 'ficheros') {
        this.$appRouter.push({
          name: 'offline__parte-fichero-list',
          params: {
            idtabla: TABLA.parte_trabajo.idtabla,
            id: parseInt(this.routeParams.idparte_trabajo),
            readonly: this.estadoParteFinalizado,
          },
          query: {
            extra: encodeExtraQuery([
              { idtabla: TABLA.orden_trabajo.idtabla, id: this.item.dataset.orden_trabajo.idorden_trabajo },
              { idtabla: TABLA.sistema.idtabla, id: this.item.dataset.sistema.idsistema },
            ]),
          },
        })
      }
    },
    clickSummaryRow (row) {
      if (row.name === 'sistema.descripcion') {
        this.showingDialogs.moreInfo = false
        this.$appRouter.push({
          name: 'offline__sistema-view',
          params: {
            idsistema: this.item.dataset.sistema.idsistema,
          }
        })
      }
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.checklist) {
        this.clickIrAChecklist()
      } else if (option === this.toolbarOptions.sistema) {
        this.$appRouter.push({
          name: 'offline__sistema-view',
          params: {
            idsistema: this.item.dataset.sistema.idsistema,
          }
        })
      } else if (option === this.toolbarOptions.modificar) {
        this.$appRouter.push({
          name: 'offline__parte-edit',
          params: {
            idparte_trabajo: this.routeParams.idparte_trabajo,
          },
        })
      } else if (option === this.toolbarOptions.iniciarCrono) {
        this.$appRouter.push({
          name: 'offline__cronometro-iniciar',
          params: {
            idparte_trabajo: this.routeParams.idparte_trabajo,
          },
        })
      } else if (option === this.toolbarOptions.detenerCrono) {
        this.$appRouter.push({
          name: 'offline__cronometro-detener',
        })
      } else if (option === this.toolbarOptions.finalizar) {
        this.clickFinalizar()
      } else if (option === this.toolbarOptions.resumenYFirma) {
        this.$appRouter.push({
          name: 'offline__parte-resumen-cliente-view',
          params: {
            idparte_trabajo: this.routeParams.idparte_trabajo,
          },
        })
      } else if (option === this.toolbarOptions.subirParte) {
        await this.clickSubirParte()
      } else if (option === this.toolbarOptions.subirParteSinFinalizar) {
        const res = await this.$alert.showConfirm('¿Deseas subir el parte de trabajo sin haberlo finalizado?')
        if (res) {
          await this.clickSubirParteSinFinalizar()
        }
      } else if (option === this.toolbarOptions.reabrirParte) {
        const res = await this.$alert.showConfirm('¿Deseas reabrir el parte de trabajo?')
        if (res) {
          await Data.reabrirParte(this, this.routeParams.idparte_trabajo)
          await this.loadPage()
          this.$alert.showSnackbarSuccess('Parte de trabajo reabierto')
        }
      } else if (option === this.toolbarOptions.marcarTodoCorrecto) {
        this.clickMarcarTodoCorrecto()
      } else if (option === this.toolbarOptions.map) {
        window.open(getLatLongURL(this.item.dataset.cliente_direccion.latitud, this.item.dataset.cliente_direccion.longitud), '_blank')
      } else if (option === this.toolbarOptions.geolocalizar) {
        this.geolocalizar()
      } else if (option === this.toolbarOptions.irOrden) {
        this.$appRouter.push({
          name: 'ordenes__orden-trabajo-view',
          params: {
            idorden_trabajo: this.item.dataset.orden_trabajo.idorden_trabajo,
          },
        })
      }
    },
    clickIrAChecklist () {
      this.$appRouter.push({
        name: 'offline__checklist-view',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
          idchecklist_ot: this.item.dataset.checklist_ot.idchecklist_ot,
        }
      })
    },
    async clickFinalizar () {
      /*
      No puedes finalizar:
      - Si el parte lo tiene otro técnico descargado
      - Si hay números de serie pendientes
      - Si tienes el cronómetro iniciado
      */
      let partePorUnSoloTecnico = true
      // si el parte está asignado a otro técnico tengo que estar online para comprobar si no lo tiene descargado.
      if (this.datasetTecnicosAsignados.length > 1) {
        if (this.networkOnline) {
          const descargadoPorOtroTecnico = await Data.descargadoPorOtroTecnico(this, this.routeParams.idparte_trabajo, this.usuarioIdtecnico)
          partePorUnSoloTecnico = !descargadoPorOtroTecnico
          if (descargadoPorOtroTecnico) {
            this.$alert.showSnackbarError('El parte está descargado por otro técnico, no puedes finalizarlo.')
          }
        } else {
          partePorUnSoloTecnico = false
          this.$alert.showSnackbarError('El parte está asignado a otro técnico y debes tener conexión a internet para poder finalizarlo.')
        }
      } else {
        partePorUnSoloTecnico = true
      }
      if (partePorUnSoloTecnico) {
        if (this.cronometroIdparte !== parseInt(this.routeParams.idparte_trabajo)) {
          this.nserieFaltantes = await Data.selectNserieFaltantes(this, this.routeParams.idparte_trabajo)
          if (this.nserieFaltantes.length > 0) {
            this.$alert.showSnackbarError(
              `Hay artículos a instalar con nº de serie obligatorio y no lo has indicado (${this.nserieFaltantes.length})`,
              { persistent: true, actions: [{ text: 'Indicar', flat: true, handler: this.clickIndicarNserieFaltantes }], }
            )
          } else {
            this.$appRouter.push({
              name: 'offline__parte-finalizar',
              params: {
                idparte_trabajo: this.routeParams.idparte_trabajo
              }
            })
          }
        } else {
          this.$alert.showSnackbarError('Debes detener el cronómetro para finalizar')
        }
      }
    },
    clickIrContactos () {
      this.$appRouter.push({
        name: 'offline__cliente-telefono-list',
        params: {
          idcliente: this.item.dataset.cliente.idcliente,
        },
        query: {
          idsistema: this.item.dataset.sistema.idsistema,
        },
      })
    },
    async clickMarcarTodoCorrecto () {
      const res = await this.$alert.showConfirm(
        `¿Deseas marcar todas las preguntas del checklist no contestadas como correctas?`
      )
      if (res) {
        this.$loading.showManual('Marcando todo correcto')
        try {
          await this.$offline.checklistOt.updateChecklistContestadoCorrecto(
            this.routeParams.idparte_trabajo,
            this.item.dataset.checklist_ot.idchecklist_ot,
            this.usuarioIdtecnico
          )
          await this.loadPage()
        } finally {
          this.$loading.hide()
        }
      }
    },
    clickBuscarMaterial () {
      this.showingDialogs.buscarMaterialAfectado = !this.showingDialogs.buscarMaterialAfectado
    },
    clickBuscarMaterialAvanzado () {
      this.showingDialogs.buscarMaterialAfectado = false
      this.$appRouter.push({
        name: 'offline__lparte-trabajo-material-afectado-list',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
          _show_filter: true,
        },
      })
    },
    async clickSubirParte() {
      // antes de subir sincronizar para poder consultar lineas de OT pendientes de instalar
      await doSync(this.$offline, this.$api, this.$loading, this.$alert)
      this.$appRouter.push({
        name: 'partes__parte-subir',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
        },
      })
    },
    async clickSubirParteSinFinalizar() {
      this.$loading.showManual('Subiendo parte sin finalizar')
      try {
        await uploadParteTrabajo(this, this.routeParams.idparte_trabajo)
      } finally {
        this.$loading.hide()
      }
      try {
        await doSync(this.$offline, this.$api, this.$loading, this.$alert)
        await this.$dirty.deleted(this.$dirty.ENTITIES.local.parteTrabajo, this.routeParams.idparte_trabajo)
        await this.$dirty.modified(this.$dirty.ENTITIES.remote.parteTrabajo, this.routeParams.idparte_trabajo)
        this.$appRouter.replace({ name: 'index' })
      } catch (e) {
        if (e instanceof SyncError) {
          await this.loadPage()
          throw(e)
        } else {
          throw(e)
        }
      }
    },
    async geolocalizar () {
      const geolocation = await captureGeolocation(this)
      if (!!geolocation?.lat && geolocation?.lng) {
        const res = await this.$alert.showConfirm(
          `¿Deseas asignar la geolocalización actual (${geolocation.lat}, ${geolocation.lng})
          a la dirección del sistema "${this.$offline.clienteDireccion.formatearDireccion(this.item.dataset.cliente_direccion)}"?`)
        if (res) {
          await Data.geolocalizar(
            this,
            this.item.dataset.cliente_direccion.idcliente_direccion,
            geolocation.lat,
            geolocation.lng
          )
          await this.loadItem()
        }
      }
    },
    async clickIndicarNserieFaltantes () {
      this.$appRouter.push({
        name: 'offline__lparte-trabajo-edit',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
          idlparte_trabajo: this.nserieFaltantes[0],
        },
        query: {
          extra: encodeExtraQuery({ ids: this.nserieFaltantes }),
        },
      })
    },
  },
}
</script>
